<template>
    <div>
        <v-list two-line subheader>
            <v-subheader>Ключ</v-subheader>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Ключ</v-list-item-title>
                    <v-list-item-subtitle>{{ _.get(model, 'code.value', '–') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Тип лицензии</v-list-item-title>
                    <v-list-item-subtitle>{{ _.get(model, 'code.license_type.name', '–') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-subheader>Активация</v-subheader>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Дата актвиации</v-list-item-title>
                    <v-list-item-subtitle>{{ _.get(model, 'created_at_datetime', '–') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Окончание срока действия</v-list-item-title>
                    <v-list-item-subtitle>{{ _.get(model, 'expires_at_datetime', '–') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>ФИО</v-list-item-title>
                    <v-list-item-subtitle>{{ _.get(model, 'name', '–') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Организация</v-list-item-title>
                    <v-list-item-subtitle>{{ _.get(model, 'company', '–') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>E-mail</v-list-item-title>
                    <v-list-item-subtitle>{{ _.get(model, 'email', '–') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Адрес</v-list-item-title>
                    <v-list-item-subtitle>{{ _.get(model, 'address', '–') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-subheader>Использование</v-subheader>

            <v-list-item>
                <v-list-item-content>
                   <v-expansion-panels accordion>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Группы: {{ _.size(groups) }}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-simple-table v-if="_.size(groups) > 0">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    ID
                                                </th>
                                                <th class="text-left">
                                                    Название
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="group in groups" :key="group.id">
                                                <td>{{ group.id }}</td>
                                                <td>{{ group.name }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                            <v-expansion-panel-header>Педагоги: {{ _.size(teachers) }}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-simple-table v-if="_.size(teachers) > 0">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    ID
                                                </th>
                                                <th class="text-left">
                                                    Логин
                                                </th>
                                                <th class="text-left">
                                                    ФИО
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="teacher in teachers" :key="teacher.id">
                                                <td>{{ teacher.id }}</td>
                                                <td>{{ teacher.login }}</td>
                                                <td>{{ teacher.name }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                            <v-expansion-panel-header>Учащиеся: {{ _.size(students) }}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-simple-table v-if="_.size(students) > 0">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    ID
                                                </th>
                                                <th class="text-left">
                                                    Логин
                                                </th>
                                                <th class="text-left">
                                                    ФИО
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="student in students" :key="student.id">
                                                <td>{{ student.id }}</td>
                                                <td>{{ student.login }}</td>
                                                <td>{{ student.name }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>
<script>
import { errorMixin, saveMixin } from '@/mixins/formMixin'

export default {
    name: 'LicenseForm',
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, saveMixin],
    data() {
        return {
            storeModule: 'license',
            license_content: null
        };
    },
    computed: {
        groups() {
            return _.get(this, 'license_content.groups', []);
        },
        teachers() {
            return _.get(this, 'license_content.teachers', []);
        },
        students() {
            return _.get(this, 'license_content.students', []);
        }
    },
    async created() {
        const { success, data } = await this.$store.dispatch('license/view', _.get(this, 'model.id', 0));
        if(success)
            this.license_content = data;
    }
}
</script>