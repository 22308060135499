<template>
    <form-view 
        v-slot="scope"
        title="Просмотр лицензии"
        update
        url-update="/license/update/:id"
        action-one="license/one"
    >
        <license-form :model="scope.model" @success="scope.onSuccess" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import LicenseForm from '@/components/forms/LicenseForm.vue'
export default {
    name: 'LicenseUpdate',
    components: { FormView, LicenseForm }
}
</script>